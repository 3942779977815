import Swal from 'sweetalert2';

export default function Tracklist({tracklist, clickSymbol, handleClick}) {

  const enlarge = (e) => {
    Swal.fire({
      imageUrl: e.target.src,
      imageWidth: 300,
      imageHeight: 300,
      background: 'black',
      showConfirmButton: false,
      showCloseButton: true,
      showCancelButton: false,
    })    
  }

  if(!tracklist) {
    return (
      <section className='tracklist'>
        <div className='table'>
          <div className='th'>
            <div></div>
            <div>Name</div>
            <div>Artist</div>
            <div>Album</div>
            <div></div>
          </div>
        </div>
      </section>
    );
  }
  else {
    return (
      <section className='tracklist'>
        <div className='table'>
          <div className='tHeaders'>
            <div></div>
            <div>Name</div>
            <div>Artist</div>
            <div>Album</div>
            <div></div>
          </div>
          <div className='tbody'>
            {tracklist.map((song) => (
              <div className='tbodyRow' key={song.id} id={song.id}>
                <div>
                  <img onClick={enlarge} src={song.albumArt}/>
                </div>
                <div>
                  {song.name}
                </div>
                <div>
                  {song.artist}
                </div>
                <div>
                  {song.name}
                </div>
                <div>
                  <span onClick={handleClick} className="material-symbols-outlined addOrRemoveSong">{clickSymbol}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    )
  };
}