import {CurrentToken} from '../modules/CurrentToken';
import Swal from 'sweetalert2';

export const Spotify = {

  clientId: '9aaf2aa510b34a4fbc43d8df09b32fb5',
  redirectUrl: 'https://spoofy.deeblaubear.com/',
  authorizationEndpoint: new URL("https://accounts.spotify.com/authorize"),
  tokenEndpoint: "https://accounts.spotify.com/api/token",
  userEndpoint: "https://api.spotify.com/v1/me",
  scope: 'user-read-private user-read-email playlist-modify-private', // For a list of scope options check here: https://developer.spotify.com/documentation/web-api/concepts/scopes
  userId: localStorage.getItem('userId'),
  url: new URL(window.location.href),

  // Click handlers
  async login() {
    await Spotify.userAuthorization();
  },

  async logout() {
    localStorage.clear();
    window.location.href = Spotify.redirectUrl;
  },

  // Encoding functions for the code challanage in "userAuthorization()"
  generateRandomString() {
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const values = crypto.getRandomValues(new Uint8Array(64));
    return values.reduce((acc, x) => acc + possible[x % possible.length], "");
  },
  
  async sha256(plain) {
    const encoder = new TextEncoder()
    const data = encoder.encode(plain)
    return window.crypto.subtle.digest('SHA-256', data)
  },

  base64encode(input) {
    return btoa(String.fromCharCode(...new Uint8Array(input)))
      .replace(/=/g, '')
      .replace(/\+/g, '-')
      .replace(/\//g, '_');
  },

  // Spotify API Functions
  async userAuthorization() {

    // Create and set the code verifier
    const codeVerifier  = this.generateRandomString(64);
    window.localStorage.setItem('code_verifier', codeVerifier);

    const hashed = await this.sha256(codeVerifier);
    const codeChallenge = this.base64encode(hashed); 

    const params =  {
      response_type: 'code',
      client_id: this.clientId,
      scope: this.scope,
      code_challenge_method: 'S256',
      code_challenge: codeChallenge,
      redirect_uri: this.redirectUrl,
    }
    
    Spotify.authorizationEndpoint.search = new URLSearchParams(params).toString();
    window.location.href = Spotify.authorizationEndpoint.toString();

  },

  get code() {
    // If the local storage for code is null and a code is found in the URL then save the code to local storage 
    if (!localStorage.getItem('code') && this.url.searchParams.get('code')) {
      localStorage.setItem('code', this.url.searchParams.get('code'));
      return localStorage.getItem('code')
    }
    // Otherwise, return the found code or null
    else {
      return localStorage.getItem('code') 
    }
  },

  async getToken() {

    // Stored when userAuthorization() is called
    const codeVerifier = localStorage.getItem('code_verifier')
  
    const payload = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        client_id: Spotify.clientId,
        grant_type: 'authorization_code',
        code: Spotify.code,
        redirect_uri: Spotify.redirectUrl,
        code_verifier: codeVerifier,
      }),
    }
  
    try {
      
      const body = await fetch(Spotify.tokenEndpoint, payload);
      const response = await body.json();
      
      if (response.error) {
        if(Spotify.url.searchParams.has('code')){
          Spotify.url.searchParams.delete('code')
          window.location.href = Spotify.url.toString()
        }
        throw new Error(response);
      }
      
      await CurrentToken.save(response);

      Spotify.url.searchParams.delete('code')
      window.location.href = Spotify.url.toString()
      
    }
    catch (e) {
      return Swal.fire({
        title: 'Error getting access token',
        text: `${e.status}: ${e.message}`,
        color: 'white',
        icon: 'info',
        confirmButtonText: 'Close',
        background: 'darkslategrey',
        iconColor: 'goldenrod',
        buttonsStyling: false
      })
    }
  },

  async refreshToken() {

    const payload = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams({
        grant_type: 'refresh_token',
        refresh_token: CurrentToken.refresh_token,
        client_id: Spotify.clientId,
      }),
    };

    const body = await fetch(Spotify.tokenEndpoint, payload);
    const response = await body.json();

    CurrentToken.save(response);
 
  },
  async isTokenExpired() {
    const now = new Date();
    if (now > CurrentToken.expires_in) {
      // The token is expired, refresh the token
      await Spotify.refreshToken();
    }
  },
  async getUserId(setUserId) {

    const payload = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${CurrentToken.access_token}`,
      },
    };

    try {
      const body = await fetch(Spotify.userEndpoint, payload);
      const response = await body.json();
      if (response.error){
        let errorMesssage = response.error.status
        errorMesssage = ': '
        errorMesssage = response.error.message
        throw new Error(errorMesssage);
      }
      window.localStorage.setItem('userId', response.id)
      setUserId(response.id)
    }
    catch(e){
      return Swal.fire({
        title: 'Error retrieving user id',
        text: e.message,
        color: 'white',
        icon: 'info',
        confirmButtonText: 'Close',
        background: 'darkslategrey',
        iconColor: 'goldenrod',
        buttonsStyling: false
      })
    }
  },

  async search(query, setSearchResults) {

    // Check if the token is expired
    await this.isTokenExpired();

    const payload = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${CurrentToken.access_token}`,
      },
    };

    const response = await fetch(`https://api.spotify.com/v1/search?q=${query}&type=track`, payload);
    const music = await response.json();

    const tracks = [];

    music.tracks.items.forEach(track => {
      tracks.push({
        id: track.id,
        name: track.name,
        artist: track.artists[0].name,
        album: track.album.name,
        albumArt: track.album.images[0].url,
        uri: track.uri
      });
    });

    setSearchResults(tracks);

  },
  async createPlaylist(playlistName, userId) {

    let id = '';

    // Check if the token is expired
    await Spotify.isTokenExpired()

    const playlistEndpoint = `https://api.spotify.com/v1/users/${userId}/playlists`;
    const playlistDetails = {
      name: playlistName,
      description: 'Created with Jammming Web App',
      public: false,
    };
    const playlistPayload = {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${CurrentToken.access_token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(playlistDetails),
    };

    try {
      // Create the playlist
        const body = await fetch(playlistEndpoint, playlistPayload)
        const response = await body.json()
        if(response.error){
          let errorMessage = response.error.status || 'Unknown Status'
          errorMessage += ': '
          errorMessage += response.error.message || 'Unknown error occurred'
          throw new Error(errorMessage)
        }
        id = response.id
    }
    catch(e){
      Swal.fire({
        title: 'Error creating playlist',
        text: e.message,
        color: 'white',
        icon: 'info',
        confirmButtonText: 'Close',
        background: 'darkslategrey',
        iconColor: 'goldenrod',
        buttonsStyling: false
      })

    }

    return id

  },
  async addTracks(playlistId, setPlaylistId, uris) {
    
    // Check if the token is expired
    await Spotify.isTokenExpired()

    try{
      const playlistTracksEndpoint = `https://api.spotify.com/v1/playlists/${playlistId}/tracks`
      const playlistTracksDetails = {
        uris: uris,
        position: 0
      };
      const playlistTracksPayload = {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${CurrentToken.access_token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(playlistTracksDetails),
      };

      const playlistTracksResponse = await fetch(playlistTracksEndpoint, playlistTracksPayload)
      if(!playlistTracksResponse.ok){
        let errorMessage = playlistTracksResponse.error.status || 'Unknown Status'
        errorMessage += ': '
        errorMessage += playlistTracksResponse.error.message || 'Unknown error occurred'
        throw new Error(errorMessage)
      }

      Swal.fire({
        title: 'Playlist and tracks successfully created',
        color: 'white',
        icon: 'success',
        confirmButtonText: 'Close',
        background: 'darkslategrey',
        buttonsStyling: false          
      })

      setPlaylistId('')

      return true

    }
    catch(e){
      Swal.fire({
        title: 'Error adding songs',
        text: e.message,
        color: 'white',
        icon: 'info',
        confirmButtonText: 'Close',
        background: 'darkslategrey',
        iconColor: 'goldenrod',
        buttonsStyling: false
      })
      return false
    }
  }

}
