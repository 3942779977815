import { useState } from 'react'
import {Spotify} from '../SpotifyAPI/Spotify'
import Swal from 'sweetalert2'

export default function SaveButton({playlist, setPlaylist,  setPlaylistName, playlistName, userId, setIsError}) {

  const [playlistId, setPlaylistId] = useState('')
  
  const saveToSpotify = async (e) => {

    e.preventDefault()

    const uriList = playlist.map(song => song.uri)

    if (playlistName === '' || playlistName === 'Enter your playlist name') {
      setIsError(true)
      return Swal.fire({
        titleText: 'You must enter a playlist name',
        icon: 'info',
        color: 'white',
        background: 'darkslategrey',
        iconColor: 'goldenrod',
        buttonsStyling: false
      })
    }
    else if (playlist.length === 0) {
      return Swal.fire({
        titleText: 'You must add music to the playlist',
        icon: 'info',
        color: 'white',
        background: 'darkslategrey',
        iconColor: 'goldenrod',
        buttonsStyling: false
      })
    }

    // Check and create playlist if necessary
    let currentPlaylistId = playlistId;
    if (currentPlaylistId === '') {
      currentPlaylistId = await Spotify.createPlaylist(playlistName, userId);
      setPlaylistId(currentPlaylistId); // Update state for future operations, though it won't be immediately available
    }

    if(currentPlaylistId !== ''){
      const saveSuccessful = await Spotify.addTracks(currentPlaylistId, setPlaylistId, uriList)
      if(saveSuccessful === true){
        setPlaylist([]);
        setPlaylistName('');
      }
    }

  }
    
  return (
    <div>
      <button className='saveButton' onClick={saveToSpotify} ><span className="material-symbols-outlined">cloud_upload</span><span className='saveButtonText'>Save to Spotify</span></button>
    </div>
  );
}