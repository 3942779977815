export default function LogInButton({handleLogIn}) {

  return (
    <div className='login'>
      <h1>Welcome to Ja<span className="mmm">mmm</span>ing!</h1>
      <p>This project was completed as part of the Full-Stack Web Development course at <a href="https://codecademy.com/">Codecademy</a>. 
      This project uses the Spotify API. You can search for song tracks, add them to a playlist, give the playlist a name, then upload this list to your Spotify account.</p>
      <p id="toUseText">To start using this service, click the button below and connect to your Spotify account.</p>
      <p id="screenSizeText">This web app is intended for use on screens with a minimum width of 1130px. I may add more functionality later but this is outside the original scope of this project.</p>
      <button onClick={handleLogIn}><span className='material-symbols-outlined'>login</span></button>
    </div>
  )

}
