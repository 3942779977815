import {useState} from 'react';
import SearchResults from './components/SearchResults';
import Playlist from './components/Playlist';
import LogInButton from './components/LogInButton';
import {CurrentToken} from './modules/CurrentToken';
import {Spotify} from './SpotifyAPI/Spotify';
import {styles} from './css/styles.css';

function App() {
  
  const [playlist, setPlaylist] = useState([]);
  const [playlistName, setPlaylistName] = useState('Enter your playlist name');
  const [searchResults, setSearchResults] = useState([]);
  const [userId, setUserId] = useState('');

  // If we find a code and we do not have an access token then get a token
  if (Spotify.code && !CurrentToken.access_token) {
    Spotify.getToken();
  }

  if (!CurrentToken.access_token) {
    return (
      <div className="App"> 
        <LogInButton accessToken={CurrentToken.access_token} handleLogIn={Spotify.login} />
      </div>
    )
  }
  else if (CurrentToken.access_token) {
    if(userId === '') {
      Spotify.getUserId(setUserId)
    } 
    return (
    <div className="App">
      <header>
        <button className='logout' onClick={Spotify.logout}><span className="material-symbols-outlined">logout</span></button>
        <h1>Ja<span className="mmm">mmm</span>ing</h1>
        <span>{userId}</span>
      </header>
      <SearchResults searchResults={searchResults} setSearchResults={setSearchResults} playlist={playlist} setPlaylist={setPlaylist}  />
      <Playlist playlist={playlist} setPlaylist={setPlaylist} playlistName={playlistName} setPlaylistName={setPlaylistName} userId={userId} />     
    </div>
    )
  }
}

export default App;