import Tracklist from './Tracklist';
import SearchBar from './SearchBar';
import Swal from 'sweetalert2';

export default function SearchResults({searchResults, setSearchResults, playlist, setPlaylist}) {
 
  const addToPlaylist = (e) => {

    // You can't send more than 100 tracks at a time https://developer.spotify.com/documentation/web-api/reference/add-tracks-to-playlist
    if(playlist.length == 100) {
      Swal.fire({
        title: 'Track Limit Reached',
        text: 'Only 100 tracks can be added per request',
        color: 'white',
        icon: 'info',
        confirmButtonColor: 'darkslategrey',
        confirmButtonText: 'Close',
        background: 'darkgoldenrod',
        iconColor: 'darkslategrey'
      })
    }

    const id = e.target.parentNode.parentNode.getAttribute("id");

    // Will either find the song index or returned -1
    const isSongInPlaylist = playlist.findIndex((song) => song.id == id);

    // Check if the value is -1 add it to the playlist
    if (isSongInPlaylist === -1) {
      setPlaylist([...playlist, searchResults.find(song => song.id == id)]);
    }
    // Otherwise return an alert that the song is already added
    else {
      Swal.fire({
        title: 'This song is already added',
        text: 'Do you want add it anyway?',
        color: 'white',
        icon: 'info',
        confirmButtonColor: 'darkslategrey',
        confirmButtonText: 'Add it anyway',
        background: 'darkgoldenrod',
        iconColor: 'darkslategrey',
        denyButtonText: 'No thanks',
        denyButtonColor: 'grey',
        showDenyButton: true
      })
      .then((result) => {
        if (result.isConfirmed) {
          // Create a new array with songs that have contain the current song ID
          const numberOfSameSongs = playlist.filter(song => song.id.includes(id)).length;
          const newId = id + numberOfSameSongs;

          // You need to create a new object and change the key and ID
          // Get the track object from the searchResults array
          const track = searchResults.find(song => song.id == id)
          // Create a new track Object
          const newTrackObject = {
            id: newId,
            name: track.name,
            artist: track.artist,
            album: track.album,
            uri: track.uri
          }
          // The below statement finds the song in the search results array so it needs to have the original ID to find it
          setPlaylist([...playlist, newTrackObject]);
        } else if (result.isDenied) {
          // Do nothing
        }
      });
    }
  }

  return (
    <div className='searchResults'>
      <SearchBar setSearchResults={setSearchResults} />
      <Tracklist tracklist={searchResults} clickSymbol={"add"} handleClick={addToPlaylist} />
    </div>
  )

}