import {useState} from 'react';
import {Spotify} from '../SpotifyAPI/Spotify';

export default function SearchBar({setSearchResults}) {
    
    const [searchText, setSearchText] = useState('Search for songs');
    
    const handleText = (e) => {
      e.preventDefault();
      setSearchText(e.target.value);
    }
    const handleSearch = async () => {
      await Spotify.search(searchText, setSearchResults);
    }
    const handleKeyPress = (e) => {
      if (e.key === 'Enter' || e.key === 'Return') {
        handleSearch();
      }
    }

  return (
    <div>
         <input 
            id="searchField"
            type="text" 
            value={searchText}
            onClick={function () {if (searchText === "Search for songs") setSearchText('')} }
            onChange={handleText}
            onKeyDown={handleKeyPress}
        />
        <button onClick={handleSearch} ><span className="material-symbols-outlined">search</span></button>
    </div>
  );
}
