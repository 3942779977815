// Data structure that manages the current active token, caching it in localStorage
export const CurrentToken = {
  get access_token() { return localStorage.getItem('access_token') || null },
  get refresh_token() { return localStorage.getItem('refresh_token') || null },
  get expires_in() { return localStorage.getItem('expires_in') || null },
  get expires() { return localStorage.getItem('expires') || null },
  get scope() {return localStorage.getItem('scope') || null },

  async save (response) {
    const { access_token, refresh_token, expires_in, scope } = response;
      localStorage.setItem('access_token', access_token);
      localStorage.setItem('refresh_token', refresh_token);
      localStorage.setItem('expires_in', expires_in);
      localStorage.setItem('scope', scope);
      const now = new Date();
      const expiry = new Date(now.getTime() + (expires_in * 1000));
      localStorage.setItem('expires', expiry);
  },
};