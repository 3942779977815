import SaveButton from './SaveButton';
import Tracklist from './Tracklist';
import { useState } from 'react';

export default function Playlist({playlist, setPlaylist, playlistName, setPlaylistName, userId}) {

  const [isError, setIsError] = useState(false);

  const removeFromPlaylist = (e) => {
    const id = e.target.parentNode.parentNode.getAttribute("id");
    setPlaylist(prevPlaylist => {
      return prevPlaylist.filter(song => song.id !== id)
    })
  }
  
  const handlePlaylistName = (e) => {
    e.preventDefault()
    setPlaylistName(e.target.value)
    if (isError) {
      setIsError(false)
    }
  }

  return (
    <div className='playlist'>
      <input id="playlistName" className={isError ? 'error' : ''} type="text" value={playlistName} onChange={handlePlaylistName} onClick={function () {if (playlistName === "Enter your playlist name") setPlaylistName('')} } />
      <SaveButton playlistName={playlistName} setPlaylistName={setPlaylistName} playlist={playlist} setPlaylist={setPlaylist} userId={userId} setIsError={setIsError} />
      <Tracklist tracklist={playlist} clickSymbol={"delete"} handleClick={removeFromPlaylist} />
    </div>
  );
}